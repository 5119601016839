<template>
  <div class="login h_100 flex flex-justify-center">
    <div class="inner_box">
      <div class="title t-center" style="margin-bottom: 20px;">
        EG GROUP 管理系统
      </div>
      <el-form ref="form" :model="param" :rules="rules">
        <el-form-item prop="name">
          <el-input
            v-model="param.account"
            placeholder="用户名"
            prefix-icon="el-icon-user"
            @keydown.enter.native="login"
          />
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            type="password"
            prefix-icon="el-icon-lock"
            v-model="param.password"
            placeholder="密码"
            show-password
            @keydown.enter.native="login"
          />
        </el-form-item>
        <el-button
          class="w_100"
          type="primary"
          :loading="loginLoading"
          @click="login"
          >登录</el-button
        >
      </el-form>
    </div>
    <div style="position: fixed;bottom: 0;display: flex;justify-self: flex-end; justify-content: flex-end;align-items: center;margin-top: 10px;">Copyright ©2024-2029 All rights reserved. &nbsp;<a href="https://beian.miit.gov.cn" target="_blank">鲁IPC备2024083426</a></div>
  </div>
</template>
<script>
import {Login} from '@/api/login'
import md5 from 'js-md5';
export default {
  name: "Login",
  data() {
    return {
      param: {
        account: "",
        password: "",
      },
      rules: {
        account: [{ required: true, message: "请输入用户名", trigger: blur }],
        password: [{ required: true, message: "请输入密码", trigger: blur }]
      },
      loginLoading: false
    };
  },
  created() {},
  methods: {
    async login() {
      let pwd = this.param.password
      let mobile = this.param.account.replace(/\s*/g,"")
      if(!pwd || !mobile){
        this.$message.info('请填写账号密码')
        return
      }
      this.loginLoading = true
      const parame = {
        account: mobile,
        pwd: md5(pwd)
      }
      const {code, token, data} = await Login(parame)
      if(code == 200){
        localStorage.setItem("eg_group_token" ,token)
        localStorage.setItem("managerType", data.author_type)
        localStorage.setItem("managerRight", data.author_right)
        localStorage.setItem("username", data.name)
        this.loginLoading = false
        const toPath = decodeURIComponent(this.$route.query?.redirect || '/')
        this.$router.replace(toPath).then(() => {
          if (this.$route.name == 'login') {
            location.reload()
            this.$router.replace('/')
          }
        })
      } else {
        this.loginLoading = false
      }

    },
  }
};
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f7f9;
  align-items: center;
  justify-content: center;
  .inner_box {
    padding: 20px;
    width: 450px;
    background: #fff;
    .title {
      // padding-bottom: 40px;
      font-size: 18px;
    }
    /deep/ .el-form-item {
      // background-color: #283443;
      border: 1px solid #ccc;
      border-radius: 4px;
      .el-input {
        input {
          height: 20px;
          padding-right: 30px;
          font-size: 13px;
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}
</style>
